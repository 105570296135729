<template>
  <div>
    <h2>Overview</h2>
    <p>
      The EverWatch Search page enables you to quickly search for all log types
      by entering a search term, as well as perform advanced search functions
      like filtering by log type, incident start date, and end date. Log
      entries, like those on the division page, are sorted by day and displayed
      in collapsibles that can be expanded to show all log entries for that day
      as well as those grouped by division.
    </p>
    <p>
      Clicking the header allows you to view each log entry within the
      collapsible. Clicking the checkbox on each log or collapsible allows you
      to select multiple logs to edit, delete, email, or print.
    </p>

    <v-img
      content-class="help-image-style"
      class="help-image"
      eager
      contain
      src="../../images/help/divison_log.png"
      transition="fade-transition"
      min-height="400px"
      max-height="400px"
    >
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
/* eslint-disable */
export default {
  name: "search_page_help",
  data() {
    return {};
  },
  methods: {
    init() {},
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
};
</script>
